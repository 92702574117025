import {Box, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useFormik} from "formik";
import {LoadingButton} from "@mui/lab";
import {Store} from "../context/DataStore";
import axios from "axios";
import { useSnackbar } from "notistack";

export default function SubscribBox() {
  const { mobileDiv , options } = Store();
  const {enqueueSnackbar}=useSnackbar()
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object({
    email: yup.string().email().required()
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: ""
    },
    onSubmit: async (values) => {
      setLoading(true);
      await axios.get(`/common/newsletter/${values.email}` ,{headers :options}).then((res) => {
        enqueueSnackbar(`${res.data.message}`, {variant: "success"});
        setLoading(false)

       }).catch((err) => {
         setLoading(false)
        enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
        console.log(err)
      })
    }
  });
  const {i18n} = useTranslation();
  return (
    <Box
      mt={5}
      sx={{}}
      bgcolor={"#2391BC"}
      height={410}
      borderRadius={"112px 0px 84px 0px"}
      pb={mobileDiv && 10}
      px={1}
    >
      <Box color={"#fff"}>
        <Typography
          fontFamily={"Tajawal-b"}
          component={"h3"}
          variant="h4"
          textAlign={"center"}
          py={5}
        >
          {i18n.t("email_subscrib")}
        </Typography>
        <Typography
          fontFamily={"Tajawal"}
          textAlign={"center"}
          component={"h5"}
          variant="h5"
        >
          {i18n.t("emil_subscrib_disc")}
        </Typography>
      </Box>
      <Box textAlign={"center"} pt={10}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="email"
            fullWidth={mobileDiv && true}
            onChange={formik.handleChange}
            placeholder={i18n.t("Email_hitt")}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            inputProps={{type: "email"}}
            sx={{
              backgroundColor: "#fff",
              width: !mobileDiv ? "400px" : "100%",
              fontFamily: "Tajawal"
            }}
          />
          <LoadingButton
            fullWidth={mobileDiv && true}
            loading={loading}
            type="submit"
            sx={{
              color: "#fff",
              padding: "10px",
              fontSize: "19px",
              bgcolor: "#68B3D4",
              fontFamily: "Tajawal",
              mt: mobileDiv && 1
            }}
            variant="contained"
          >
            {i18n.t("subscrib")}
          </LoadingButton>
        </form>
      </Box>
    </Box>
  );
}
