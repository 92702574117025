import {Box, Container, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import BasicMenu from "./MenuSelectItems";
import {useTranslation} from "react-i18next";
import {sortList} from "../../data/Lists";
import {Store} from "../../context/DataStore";
import axios from "axios";

export default function FilterBox({fun}) {
  const [items, setItems] = useState("");
  const [cities, setCities] = useState("");
  const {mobileDiv, options} = Store();
  const {i18n} = useTranslation();

  const getItems = async () => {
    await axios
      .get("/lookups/item-types", {headers: options})
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCities = async () => {
    await axios
      .get("/lookups/cities", {headers: options})
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getItems();
    getCities();
  }, []);
  return (
    <Box bgcolor={"#fff"} width={"90%"} borderRadius={3} py={1}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: !mobileDiv ? "center" : "start",
          flexDirection: mobileDiv && "column",
          gap: 3
        }}
      >
        <Box display={"flex"} gap={3} alignItems={"center"}>
          <BasicMenu
            title={"product_page.search_box.menu.title"}
            items={items ? items : []}
            fun={fun}
            findUrl={"/real-estate-devs/items/filter/type"}
          />
          <BasicMenu
            title={"product_page.search_box.city"}
            items={cities ? cities : []}
            fun={fun}
            findUrl={"/real-estate-devs/items/filter/city"}
          />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
          width={mobileDiv ? "70%" : null}
        >
          <Typography>{i18n.t("product_page.search_box.solt")}</Typography>
          <BasicMenu
            title={"product_page.search_box.events"}
            items={sortList}
            fun={fun}
          />
        </Box>
      </Container>
    </Box>
  );
}
