import {Box} from "@mui/material";
import React from "react";
import styles from "../css/home.module.css";
import {coins, heand_left, right_hand, firstBox_bg} from "../img";

export default function TrlPic() {
  return (
    <Box
      width={"555px"}
      display={"flex"}
      alignItems={"center"}
      position={"relative"}
      right={"10%"}
      top={"15%"}
      height={"560px"}
    >
      <Box
        className={styles.imgBox}
        sx={{
          backgroundImage: `url(${firstBox_bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain"
        }}
        width={"555px"}
        display={"flex"}
        alignItems={"center"}
        position={"relative"}
        height={"560px"}
      >
        <Box width={333} position={"relative"} zIndex={3} left={"5%"}>
          <img src={heand_left} alt="heand_left" width={"100%"} />
        </Box>

        <Box
          width={230}
          zIndex={2}
          position={"relative"}
          top={"10%"}
          right={"2%"}
        >
          <img src={right_hand} alt="right_hand" width={"100%"} />
        </Box>
      </Box>
      <Box position={"relative"} zIndex={3} bottom={"-25%"} right={"65%"}>
        <img src={coins} alt="right_hand" />
      </Box>
    </Box>
  );
}
