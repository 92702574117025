import {
  Box,
  FormControl,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import RTLBox from "./RTLBox";
import {useSnackbar} from "notistack";
import axios from "axios";
import {Store} from "../context/DataStore";
import {useNavigate} from "react-router-dom";

export default function RegisterForm({fun}) {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const {options,} = Store();
  const {i18n} = useTranslation();
  const regex = /(05|01)[0-9]{8}/;

  const validationSchema = yup.object({
    company_name: yup
      .string()
      .min(2)
      .required(i18n.t("login.companyName_required")),
    // company_type: yup.number().required(i18n.t("login.company_type_required")),
    email: yup.string().email().required(i18n.t("login.email_required")),
    phone: yup
      .string()
      .matches(regex, i18n.t("g.vaild_phone"))
      .max(10, i18n.t("g.vaild_phone_number"))
      .required(i18n.t("login.Phone_required")),
    password: yup.string().required(i18n.t("login.Password_required")),
    cpassword: yup.string().required(i18n.t("login.cPassword_required"))
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      company_name: "",
      company_type: "",
      email: "",
      phone: "",
      password: "",
      cpassword: ""
    },
    onSubmit: async (values) => {
      setLoading(true);
      if (values.cpassword !== values.password) {
        closeSnackbar();
        enqueueSnackbar(i18n.t("g.pass_not_match"), {variant: "error"});
        setLoading(false);
        return;
      }
      const newCompany = {
        name: values.company_name,
        mobile: values.phone,
        email: values.email,
        password: values.password,
        company_type: values.company_type
      };

      await axios
        .post("/real-estate-devs/register", newCompany, {
          headers: options
        })
        .then((res) => {
          if (res.data.success === true) {
            localStorage.setItem(
              "userToken",
              JSON.stringify(res.data.data.token)
            );
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));

            enqueueSnackbar(`${res.data.message}`, {variant: "success"});
            setLoading(false);
            formik.handleReset()
            fun();
          }
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
          console.log(err);
        });
    }
  });
  return (
    <Box width={"100%"}>
      <form onSubmit={formik.handleSubmit}>
        <RTLBox>
          <List sx={{textTransform: "capitalize"}}>
            <ListItem>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                gap={1}
                width={"100%"}
              >
                <TextField
                  fullWidth
                  id="company_name"
                  name="company_name"
                  label={i18n.t("login.company_name")}
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.company_name &&
                    Boolean(formik.errors.company_name)
                  }
                  helperText={
                    formik.touched.company_name && formik.errors.company_name
                  }
                />
                <FormControl fullWidth>
                  <InputLabel id={"company_type_label"}>
                    {i18n.t("login.company_type")}{" "}
                  </InputLabel>
                  <Select
                    fullWidth
                    defaultValue={0}
                    id="company_type"
                    name="company_type"
                    labelId="company_type_label"
                    label={i18n.t("login.company_type")}
                    onChange={formik.handleChange}
                    // error={
                    //   formik.touched.company_type &&
                    //   Boolean(formik.errors.company_type)
                    // }
                    // helperText={
                    //   formik.touched.company_type && formik.errors.company_type
                    // }
                  >
                    <MenuItem value={0}>{i18n.t('g.company_type')} </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </ListItem>
            <ListItem>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                gap={1}
                width={"100%"}
              >
                <TextField
                  fullWidth
                  inputProps={{type: "email"}}
                  id="email"
                  name="email"
                  label={i18n.t("login.email")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />

                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  label={i18n.t("login.phone")}
                  value={formik.values.phone}
                  inputProps={{type: "text", maxLength: 10}}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Box>
            </ListItem>
            <ListItem>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                gap={1}
                width={"100%"}
              >
                <TextField
                  fullWidth
                  inputProps={{type: "password"}}
                  id="password"
                  name="password"
                  autoComplete="password"
                  label={i18n.t("login.password")}
                  // value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <TextField
                  fullWidth
                  id="cpassword"
                  name="cpassword"
                  inputProps={{type: "password"}}
                  label={i18n.t("login.cpassword")}
                  autoComplete="password"
                  // value={formik.values.cpassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cpassword && Boolean(formik.errors.cpassword)
                  }
                  helperText={
                    formik.touched.cpassword && formik.errors.cpassword
                  }
                />
              </Box>
            </ListItem>
            <ListItem sx={{display: "flex", alignItems: "center", gap: 1}}>
              <Input
                type="checkbox"
                id="checkbox"
                name="checkbox"
                value={check}
                onChange={() => {
                  setCheck(!check);
                }}
              />
              <Typography>{i18n.t("login.checkbox")}</Typography>
            </ListItem>
            <ListItem>
              <LoadingButton
                loading={loading}
                disabled={!check}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  borderRadius: "15px 50px",
                  bgcolor: "#2391BC",
                  fontFamily: "Tajawal-b"
                }}
              >
                {i18n.t("login.createAccount")}
              </LoadingButton>
            </ListItem>
          </List>
        </RTLBox>
      </form>
    </Box>
  );
}
