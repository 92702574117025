import {
  BusinessCenterOutlined,
  NotificationsOutlined
} from "@mui/icons-material";
import { Badge, Box, IconButton, Typography} from "@mui/material";
import React from "react";
import { message_outLien} from "../../img";
import ProfileMenuAdmin from "./ProfileMenuAdmin";
import { Store } from "../../context/DataStore";

export default function NavigateNavAdmin() {
  const {userInfo}=Store()
  return (
    <Box
      display={"flex"}
      justifyContent={"centr"}
      alignItems={"center"}
      gap={2}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
      >
        <Badge badgeContent={1} color="error" overlap="circular">
          <IconButton sx={{border :".5px solid #666"}} variant="outlined">
            <NotificationsOutlined sx={{color: "#666"}} />
          </IconButton>
        </Badge>
        {/* <Badge badgeContent={1} color="success" overlap="circular">
          <IconButton sx={{border :".5px solid #666"}} variant="outlined">
            <img src={message_outLien} alt="message_outLien" width={25} />
          </IconButton>
        </Badge>
        <Badge badgeContent={1} color="error" overlap="circular">
          <IconButton sx={{border :".5px solid #666"}} variant="outlined">
            <BusinessCenterOutlined sx={{color: "#666"}} />
          </IconButton>
        </Badge> */}
      </Box>
      <Box
        display={"flex"}
        justifyContent={"end"}
        alignItems={"start"}
        sx={{direction: "ltr"}}
        gap={1}
              border={".5px solid #eeeeee"}
              px={1}
              borderRadius={2}
      >
        <ProfileMenuAdmin />
        <Box>
          <Typography color={"#000"} component={"h6"} variant="h6">
            {userInfo?.name}
          </Typography>
          <Typography variant="subtitle1" color={"#BDBDBD"}>
            {userInfo?.role}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
