import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import {Rectangle_5} from "../../img";
import {Store} from "../../context/DataStore";
import {useNavigate} from "react-router-dom";
import AdminInfo from "./AdminInfo";
import { useTranslation } from "react-i18next";

export default function ProfileMenuAdmin() {
  const {i18n} = useTranslation()
  const {setUserInfo, setUserToken, setAdminState} = Store();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function logout() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userInfo");
    setUserInfo(null);
    setUserToken(null);
    setAdminState(false);
    navigate("/");
    handleClose();
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          direction: "ltr"
        }}
      >
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            // sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar src={Rectangle_5} sx={{width: 32, height: 32}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        dir="ltr"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 84,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{horizontal: "right", vertical: "top"}}
        anchorOrigin={{horizontal: "right", vertical: "bottom"}}
      >
        <MenuItem>
          <AdminInfo fun={handleClose} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout }>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {i18n.t("g.logout")}
        </MenuItem>
      </Menu>
    </Box>
  );
}
