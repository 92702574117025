import {Close, ReceiptLongOutlined} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export default function Reportes({data}) {
  const [open, setOpen] = useState(false);
  const {i18n} = useTranslation();
  console.log(data);
  return (
    <Box>
      <IconButton onClick={() => setOpen(true)}>
        <ReceiptLongOutlined sx={{color: "#2391bc"}} />
      </IconButton>

      <Dialog open={open} fullWidth>
        <Box p={2}>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <Typography
          py={1}
          variant="h6"
          fontFamily={"Tajawal-b"}
          textAlign={"center"}
        >
          {i18n.t("g.reportes")}
        </Typography>
              <DialogContent>
                  {data?.length === 0 ? (
                    <Typography
          py={1}
          variant="h6"
          fontFamily={"Tajawal-b"}
          textAlign={"center"}
                      >
                          {i18n.t("g.reportes_empty")}
                          
                  </Typography>
                  ) : (
          <Box px={2}>
            {data?.map((ele, index) => (
              <Typography
                component={"a"}
                target="_blank"
                href={ele}
                key={index}
                sx={{textDecoration: "none"}}
              >
                {index + 1}
                {"_"} &nbsp;
                {ele}
              </Typography>
            ))}
          </Box>
                  )}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}></Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
