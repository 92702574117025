import {
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  List,
  ListItem,
  TextField
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {logo_1} from "../img";
import {Close} from "@mui/icons-material";
import {useFormik} from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";

export default function ForgotPassword({fun}) {
  const [open, setOpen] = useState(false);
  const {i18n} = useTranslation();
  const validationSchema = yup.object({
    email_phone: yup.string().required(i18n.t("login.email_phone_required"))
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      email_phone: ""
    }
    // enqueueSnackbar(`${err.response.data.message}`, {variant:"error"},)

  });
  function openHander() {
    setOpen(true);
  }
  function closeHander() {
    setOpen(false);
  }

  return (
    <Box>
      <Button variant="text" onClick={openHander}>
        {i18n.t("login.forgotPassword")}
      </Button>
      <Dialog open={open} fullWidth>
        <Box width={"100%"} p={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={3}
          >
            <img src={logo_1} alt="logo" width={100} />
            <IconButton onClick={closeHander}>
              <Close />
            </IconButton>
          </Box>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <List>
                <ListItem>
                  <TextField
                    fullWidth
                    name="email_phone"
                    label={i18n.t("login.email_phone")}
                    value={formik.values.email_phone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.email_phone &&
                      Boolean(formik.errors.email_phone)
                    }
                    helperText={
                      formik.touched.email_phone && formik.errors.email_phone
                    }
                  />
                </ListItem>
                <ListItem>
                  <LoadingButton variant="contained" type="submit">
                    {i18n.t("login.send")}
                  </LoadingButton>
                </ListItem>
              </List>
            </form>
          </Container>
        </Box>
      </Dialog>
    </Box>
  );
}
