import React from "react";
import {logo_1} from "../../img/index";
import {Box, Button, IconButton} from "@mui/material";
import {Store} from "../../context/DataStore";
import HeaderMenu from "./HeaderMenu";
import MobileDrower from "./MobileDrower";
import {useTranslation} from "react-i18next";
import { LanguageOutlined } from "@mui/icons-material";
import LogoIn from "../LogoIn";
import { changeLanguage } from "i18next";
import { Link } from "react-router-dom";

export default function Navbar() {
  const {i18n} = useTranslation();
  const { mobileDiv, changeLang } = Store();
    const list = [
    {title: "HeaderMenu.Home", path: ""},
    {title: "HeaderMenu.About", path: ""},
    {title: "HeaderMenu.products", path: "/real_estate_units"},
    {title: "HeaderMenu.connect", path: ""}
    ];
  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box>
        <Link to={'/'}>
          <img src={logo_1} alt="logo-1" width={150} />
        </Link>
      </Box>
      <Box>
        {mobileDiv ? (
          <MobileDrower data={list} />
        ) : (
          <React.Fragment>
            <HeaderMenu />
          </React.Fragment>
        )}
      </Box>
      {!mobileDiv && (
      <Box display={'flex'} gap={2} sx={{fontFamily:"Tajawal-b"}}>
          {/* <BasicMenu outLien title={i18n.t("HeaderMenu.servise_Buttom")} data={servise_Buttom} /> */}
          <LogoIn/>
          {/* <IconButton onClick={() => { changeLang(i18n.language) } }>
            <LanguageOutlined sx={{color :"#5494AC"}} />
          </IconButton> */}
      </Box>

      )}
    </Box>
  );
}
