import {Box, List, ListItemButton, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Store } from "../../context/DataStore";

export default function HeaderMenu() {
  const {showVaule} = Store();

  const navigate = useNavigate()
  const {i18n} = useTranslation();
  const list = [
    {title: "HeaderMenu.Home", path: "/"},
    {title: "HeaderMenu.About", path: "/about"},
    {title: "HeaderMenu.privacy_policy", path: "/privacy_policy"},
    {title: "HeaderMenu.connect", path: "/contactus"}
  ];
  return (
    <Box>
      <List
        sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
      >
        {list.map((ele, index) => (
          <ListItemButton className="hvr-float-shadow"
            onClick={() => { navigate(`${ele.path}`); showVaule()}}
            sx={{
              borderBottom: "2px solid transparent ",
                    transition: "ease-in-out .6s",
              fontFamily:"Tajawal-b",
              ":hover": {borderBottom: "2px solid ", bgcolor: "#fff"}
            }}
            key={index}
          >
            <Typography  fontFamily={'Tajawal'} fontWeight={700}>
            {i18n.t(`${ele.title}`)}

            </Typography>
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}
