import {Box, Button, List, ListItem, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ForgotPassword from "./ForgotPassword";
import {LoadingButton} from "@mui/lab";
import RTLBox from "./RTLBox";
import {Store} from "../context/DataStore";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

export default function LoginForm({fun}) {
  const navigate = useNavigate();
  const {enqueueSnackbar,} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const {setUserInfo, setUserToken, options, setAdminState, userInfo} = Store();
  const regex = /(05|01)[0-9]{8}/;
  const {i18n} = useTranslation();
  const validationSchema = yup.object({
    mobile: yup
     .string()
     .matches(regex, i18n.t("g.vaild_phone"))
     .max(10, i18n.t("g.vaild_phone_number"))
     .required(i18n.t("login.Phone_required")),
    password: yup.string().required(i18n.t("login.Password_required"))
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      mobile: "",
      password: ""
    },
    onSubmit: async (values) => {
      setLoading(true);
      await axios
       .post("/common/login", values, {headers: options})
       .then((res) => {
         if (res.status === 200) {
           localStorage.setItem(
            "userToken",
            JSON.stringify(res.data.data.token)
           );
           localStorage.setItem("userInfo", JSON.stringify(res.data.data));
           setUserInfo(res.data.data);
           setUserToken(res.data.data.token);
           setLoading(false);
           enqueueSnackbar(`${res.data.message}`, {variant: "success"});
           if (res.data.data.role === "admin") {
             setAdminState(true);
             navigate("/dashboard");
           }
           if (res.data.data.role === "developer") {
             setAdminState(false);
             navigate("/dashboard/developers");
           }
           if (res.data.data.role === "sales") {
             setAdminState(false);
             navigate("/dashboard/sales_representative");
           }
         }
       })
       .catch((err) => {
         setLoading(false);
         enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
         console.log(err);
       });
    }
  });
  return (
   <Box width={"100%"}>
     <RTLBox>
       <form onSubmit={formik.handleSubmit}>
         <List sx={{textTransform: "capitalize"}}>
           <ListItem>
             <TextField
              fullWidth
              id="phone"
              name="mobile"
              label={i18n.t("login.phone")}
              value={formik.values.mobile}
              onChange={formik.handleChange}
              inputProps={{type: "text", maxLength: 10}}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
             />
           </ListItem>
           <ListItem>
             <TextField
              fullWidth
              inputProps={{type: "password"}}
              id="password"
              name="password"
              label={i18n.t("login.password")}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
               formik.touched.password && Boolean(formik.errors.password)
              }
              helperText={formik.touched.password && formik.errors.password}
             />
           </ListItem>
           <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
           >
             <ForgotPassword/>
             <Button
              onClick={() => {
                fun(null, 1);
              }}
              sx={{color: "#666"}}
             >
               {i18n.t("login.addAccount")}
               <Typography px={0.5} color={"#2391BC"}>
                 {i18n.t("login.now")}
               </Typography>
             </Button>
           </ListItem>
           <ListItem>
             <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                borderRadius: "15px 50px",
                bgcolor: "#2391BC",
                fontFamily: "Tajawal-b"
              }}
             >
               {i18n.t("login.login")}
             </LoadingButton>
           </ListItem>
         </List>
       </form>
     </RTLBox>
   </Box>
  );
}
