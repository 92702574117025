import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {logo_1} from "../img";
import {Close} from "@mui/icons-material";
import LoginTab from "./LoginTab";

export default function LogoIn() {
  const {i18n} = useTranslation();
  const [open, setOpen] = useState(false);
  function closeHandler() {
    setOpen(false);
  }
  function openHandler() {
    setOpen(true);
  }
  return (
    <Box >
      <Button
        onClick={openHandler}
        sx={{fontFamily: "Tajawal-b", border: ".5px solid", px: 3, py: 1.5}}
        variant="outlined"
      >
        {i18n.t("HeaderMenu.login_Buttom")}
      </Button>

      <Dialog
        open={open}
        onClose={closeHandler}
        fullWidth
      >
        <DialogContent>

        <Box width={"100%"} p={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={3}
          >
            <img src={logo_1} alt="logo" width={100} />
            <IconButton onClick={closeHandler}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box py={5}>
          <LoginTab fun={closeHandler} />
        </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
