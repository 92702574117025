import {Box, Grid} from "@mui/material";
import React from "react";
import ProductCard from "./ProductCard";
import {cardImage_1, cardImage_2, cardImage_3} from "../img";
import MastarTitle from "./MastarTitle";

export default function Products() {
  const list = [
    {
      title: "product_box.cards.card_3.title",
      img: cardImage_3,
      sub_title: "product_box.cards.card_3.sub_title",
      disc: "product_box.cards.card_3.disc",
      link: "/real_estate_units"
    },
    {
      title: "product_box.cards.card_2.title",
      img: cardImage_2,
      sub_title: "product_box.cards.card_2.sub_title",
      disc: "product_box.cards.card_2.disc",
      link: "/partners"
    },
    {
      title: "product_box.cards.card_1.title",
      img: cardImage_1,
      sub_title: "product_box.cards.card_1.sub_title",
      disc: "product_box.cards.card_1.disc",
      link: "/payment"
    }
  ];
  return (
    <Box mt={20} position={"relative"}>
      <MastarTitle title={'product_box.title'}/>
      <Box mt={10}>
        <Grid container spacing={5}>
          {list.map((ele, index) => (
            <Grid item md={4} sm={6} xs={12} key={index}>
              <ProductCard data={ele} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
