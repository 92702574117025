import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import {contactus} from "../img";
import {useTranslation} from "react-i18next";
import RTLBox from "../components/RTLBox";
import { Store } from "../context/DataStore";

export default function Contactus() {
  const { i18n } = useTranslation();
  const {setShow} = Store();
  useEffect(() => {
    setShow(false);
  }, []);


  return (
    <Box height={"100%"} position={"relative"} pb={5}>
      <Typography
        fontFamily={"Tajawal-b"}
        fontWeight={800}
        component={"h1"}
        variant="h5"
        textAlign={"center"}
        py={2}
      >
        {i18n.t("HeaderMenu.connect")}
      </Typography>

      <Box py={5}>
        <Container sx={{height: ""}}>
          <Grid container spacing={1} justifyContent={"space-between"}>
            <Grid item md={7} bgcolor={"#F3FAFC"} borderRadius={5}>
              <Box p={5}>
              <RTLBox>
                <form>
                  <List>
                    <ListItem>
                      <TextField
                        size="small"
                        label={i18n.t("AddNewClient.name")}
                        placeholder=""
                        fullWidth
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                      label={i18n.t("AddNewClient.email")}
                        placeholder=""
                        fullWidth
                        size="small"
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        size="small"
                        label={i18n.t("login.phone")}
                        placeholder=""
                        fullWidth
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        label={i18n.t("g.message")}
                        placeholder=""
                        fullWidth
                        multiline
                        rows={5}
                        size="small"
                      />
                    </ListItem>
                    <ListItem
                      sx={{
                        width: "100%",
                        justifyContent: "end",
                        display: "flex"
                      }}
                    >
                        <Button variant="contained">{i18n.t("login.send") }</Button>
                    </ListItem>
                  </List>
                </form>
              </RTLBox>

              </Box>
            </Grid>
            <Grid item md={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Box
                p={2}
                border={"2px solid #5494AC"}
                borderRadius={"97px 15px 97px 16px"}
                width={'100%'}
              >
                <img src={contactus} alt="Tajawal" width={"100%"} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
