import {Box, Container, Grid, Typography} from "@mui/material";
import React from "react";
import styles from "../css/home.module.css";
import {firstBox_bg, heand_left, right_hand, coins} from "../img/index";
import {Store} from "../context/DataStore";
import {useTranslation} from "react-i18next";
import HighQuality from "../components/HighQuality";
import Products from "../components/Products";
import GridServices from "../components/GridServices";
import {services} from "../data/Lists";
import TrlPic from "../components/TrlPic";

export default function Home() {
  const {i18n} = useTranslation();
  const {mobileDiv} = Store();

  const list = [
    "fist_box.List_Quality.1",
    "fist_box.List_Quality.2",
    "fist_box.List_Quality.3"
  ];
  return (
    <Box>
      <Container>
        <Box className={i18n.language==="arEG"? styles.firstBox : styles.firstBox_ar}>
          <Grid container spacing={1}>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              position={"relative"}
              textAlign={"center"}
            >
              {i18n.language === "arEG" ? (<>
              <Box
                className={styles.imgBox}
                sx={{
                  backgroundImage: `url(${firstBox_bg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain"
                }}
                width={"555px"}
                display={"flex"}
                alignItems={"center"}
                position={"relative"}
                left={"10%"}
                top={"25%"}
                height={"560px"}
              >
                <Box
                  width={230}
                  zIndex={2}
                  position={"relative"}
                  top={"10%"}
                  right={"2%"}
                >
                  <img src={right_hand} alt="right_hand" width={"100%"} />
                </Box>
                <Box width={333} position={"relative"} zIndex={3} left={"5%"}>
                  <img src={heand_left} alt="heand_left" width={"100%"} />
                </Box>
              </Box>
              <Box position={"relative"} zIndex={3} bottom={"5%"} left={"10%"}>
                <img src={coins} alt="right_hand" />
              </Box>

              </>):(<TrlPic/>)}
            </Grid>
            <Grid item md={6} sm={6} xs={12} textAlign={"center"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-around"}
                height={"500px"}
                px={2}
                alignItems={mobileDiv && "center"}
                pt={mobileDiv && 5}
              >
                <Typography
                  color={"#fff"}
                  fontFamily={"Tajawal-b"}
                  component={"h1"}
                  variant="h4"
                  pt={5}
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection={mobileDiv && "column"}
                >
                <Typography color={'#fff'}  fontFamily={"Tajawal-b"}
                component={"span"}
                variant="h3">
               {i18n.t("g.no")}
                </Typography>
                <Typography
                    fontFamily={"Tajawal-b"}
                    component={"span"}
                    variant="h4"
                    p={1}
                    borderRadius={2}
                    sx={{bgcolor: "#2491bc"}}
                    fontWeight={800}
                  >
                    {" "}
                    {i18n.t("g.money")}
                  </Typography>
                </Typography>
                <Typography
                  width={"100%"}
                  fontFamily={"Tajawal-b"}
                  maxWidth={500}
                  component={"span"}
                  variant="h5"
                  color={"#0D83B1"}
                >
                  {i18n.t("fist_box.sub_title")}
                </Typography>
                <Box
                  display={"flex"}
                  justifyContent={"space-evenly"}
                  alignItems={mobileDiv ? "start" : "center"}
                  width={"100%"}
                  flexDirection={mobileDiv && "column"}
                  gap={2}
                >
                  {list.map((ele, index) => (
                    <HighQuality title={ele} key={index} />
                  ))}
                </Box>
                <Box
                  bgcolor={"#2391BC"}
                  borderRadius={"19px 0px 19px 0px"}
                  p={1}
                >
                  <Typography
                    fontWeight={800}
                    fontFamily={"Tajawal-b"}
                    color={"#fff"}
                    textAlign={"center"}
                    component={"h2"}
                    variant="h6"
                  >
                    {i18n.t("fist_box.sub_title_2")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Products />
        <Box pb={mobileDiv ? 30 : 50} pt={5}>
          {services.map((ele, index) => (
            <GridServices data={ele} key={index} dir={index === 1 && true} />
          ))}
        </Box>
      </Container>
    </Box>
  );
}
