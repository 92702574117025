import {Cancel, Delete} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
import axios from "axios";
import {useSnackbar} from "notistack";
import React, {useState} from "react";
import {Store} from "../../context/DataStore";
import {useTranslation} from "react-i18next";

export default function DeleteDailog({title, mobile, message, fun, name}) {
  const {enqueueSnackbar} = useSnackbar();
  const {i18n} = useTranslation();
  const {options} = Store();
  const [open, setOpen] = useState(false);
  const deleteItem = async () => {
    console.log(mobile);
    await axios
      .delete(`/common/delete-user/${mobile}`, {headers: options})
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(`${res.data.message}`, {variant: "success"});
          fun();
          setOpen(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
      });
  };
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Delete color="error" />
      </IconButton>
      <Dialog open={open} fullWidth>
        <DialogTitle>
          <Typography>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color={'red'} variant="h6" fontWeight={900}>
            {i18n.t(message) + `${name}`}

          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteItem}
            dir="ltr"
            startIcon={<Delete />}
            color="error"
            sx={{fontSize :"16px"}}
          >
           {i18n.t('delete_messages.delete')}
          </Button>
          <Button
            dir="ltr"
            onClick={() => setOpen(false)}
            startIcon={<Cancel />}
            color="primary"
            sx={{fontSize :"16px"}}

          >
                       {i18n.t('delete_messages.cancel')}

          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
