import {Box, Typography} from "@mui/material";
import React from "react";
import styles from "../css/home.module.css";
import {useTranslation} from "react-i18next";

export default function MastarTitle({title}) {
  const {i18n} = useTranslation();
  return (
    <Typography
      variant="h4"
      position={"relative"}
      fontFamily={"Tajawal-b"}
      component={"h2"}
      color={"#5494AC"}
      textAlign={"center"}
      className={styles.product_title}

    >
      {i18n.t(title)}
      <Box
        position={"absolute"}
        alignItems={"center"}
        m={"auto"}
        width={"40%"}
        display={"flex"}
        justifyContent={"space-between"}
        height={30}
        top={"10%"}
        right={"30%"}
      >
        <Box
          bgcolor={"#09c"}
          width={10}
          height={10}
          sx={{transform: "rotate(45deg)"}}
        ></Box>
        <Box
          bgcolor={"#09c"}
          width={10}
          height={10}
          sx={{transform: "rotate(45deg)"}}
        ></Box>
      </Box>
    </Typography>
  );
}
