import AdminsTable from '../components/admin/AdminsTable'

export default function Suopervies() {

  return (
    <>
      <AdminsTable/>
      
    </>
  )
}
