import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Card,
  Input,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import *as yup from 'yup'
import Steper from "../components/Steper";

export default function EmkanConfig() {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileCode, setMobileCode] = useState(false);
  function closeHandler() {
    setOpen(false);
  }
  function openHandler() {
    setOpen(true);
  }
  const v = yup.object({
    voucherCode: yup.string().required(i18n.t("g.voucher_code_r")),
    nationalId: yup.string().required(i18n.t("g.national_id_r")),
    applicationId: yup.string().required(i18n.t("g.application_id_r"))
  });
  return (
    <div>
      <Button variant="contained" onClick={openHandler}>
        {i18n.t("Partens.order")}
      </Button>
      <Dialog open={open} onClose={closeHandler} fullWidth sx={{ py: 1 }}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          {/* {!mobileCode ? (
            <form>
              <Card
                sx={{
                  p: 5,
                  maxWidth: "60%",
                  marginX: "auto",
                  border: "1px solid #5494AC",
                  borderRadius: "15px"
                }}
              >
                <Typography
                  align="center"
                  fontWeight={700}
                  variant="h4"
                  component={"h1"}
                >
                  Redeem Voucher
                </Typography>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <label>{i18n.t("g.voucher_code")} </label>
                    <TextField variant="outlined" size="small" fullWidth />
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <label> {i18n.t("g.national_id")}</label>
                    <TextField variant="outlined" size="small" fullWidth />
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <label> {i18n.t("g.application_id")}</label>
                    <TextField variant="outlined" size="small" fullWidth />
                  </Box>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    sx={{ color: "#000", fontWeight: 600 }}
                  >
                    Check out
                  </LoadingButton>
                </Box>
              </Card>
            </form>
          ) : (
            <form>
              <Card
                sx={{
                  p: 5,
                  maxWidth: "60%",
                  marginX: "auto",
                  border: "1px solid #5494AC",
                  borderRadius: "15px"
                }}
              >
                <Typography
                  align="center"
                  fontWeight={700}
                  variant="h4"
                  component={"h1"}
                >
                  Redeem Voucher
                </Typography>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <label>{i18n.t("g.voucher_code")} </label>
                    <TextField variant="outlined" size="small" fullWidth />
                  </Box>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    sx={{ color: "#000", fontWeight: 600 }}
                  >
                    Check out
                  </LoadingButton>
                </Box>
              </Card>
            </form>
          )} */}
          <Steper />
        </DialogContent>
      </Dialog>
    </div>
  );
}
