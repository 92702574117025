import { SearchRounded} from "@mui/icons-material";
import {Box, InputBase} from "@mui/material";
import React from "react";

export default function SearchAdminBox() {
  return (
    <Box
      border={".5px solid #c3c3c3"}
      borderRadius={1}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={1}
      width={400}
    >
      <SearchRounded sx={{color: "#666666"}} />
      <InputBase placeholder="البحث..." sx={{px: 1}}  fullWidth/>
    </Box>
  );
}
