import {Box, Card, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export default function InfoBox({ele , statistics}) {
  const {i18n} = useTranslation();
  return (
    <Box width={"100%"}>
      <Card
        sx={{
          py: 1,
          width: "100%"
        }}

      >
        <Box>
          <Typography height={40} color={"#B1B1B1"} px={2} variant="body1" fontFamily={"Tajawal-b"}>
            {i18n.t(ele.title)}
          </Typography>
        </Box>
        <Box display={"flex"} px={1} alignItems={"center"}>
          <img src={ele.img} alt="img-1" width={30} height={30} />
          <Box width={"100%"} p={1}>
            <Box
              px={1}
              borderRight={"2px solid #793C8A"}
              display={"flex"}
              justifyContent={'space-between'}
              alignItems={"center"}
              gap={2}
            >
              <Typography
                variant="h5"
                fontFamily={"Tajawal-b"}
                fontWeight={900}
              >
                {ele.number}
              </Typography>
              <Typography>{i18n.t(ele.label)}</Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
