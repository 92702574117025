import {Box, Grid, List, ListItem, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import styles from "../css/home.module.css";
import {arrow} from "../img";

export default function GridServices({data, dir}) {
  const {i18n} = useTranslation();
  return (
    <Box>
      <Grid container spacing={1} my={5} sx={{direction: dir ? "ltr" : "rtl"}}>
        <Grid item md={6} sm={4} xs={12} sx={{direction: "rtl"}}>
          <Box height={"100%"} display={"flex"} alignItems={"center"}>
            <Box
              bgcolor={"#F3FAFC"}
              borderRadius={"40px 0px"}
              display={"flex"}
              alignItems={"center"}
            >
              <List sx={{p: 5}}>
                <ListItem>
                  <Typography
                    fontFamily={"Tajawal-b"}
                    component={"h2"}
                    variant="h5"
                    width={"100%"}
                    textAlign={i18n.language === "arEG"? "start" :"end"}

                  >
                    {i18n.t(data.title)}
               
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    fontFamily={"Tajawal"}
                    variant="h6"
                    m={"auto"}
                    width={"90%"}
                    color={"#ACABAB"}
                    textAlign={i18n.language === "arEG"? "start" :"end"}
                  >
                    {i18n.t(data.disc)}
                  </Typography>
                </ListItem>
                <ListItem >
                  <Typography
                    fontFamily={"Tajawal"}
                    component={"a"}
                    href={data.path}
                    color={"inherit"}
                    variant="h6"
                    width={i18n.language === "arEG"? null:"100%"}
                    textAlign={i18n.language === "arEG"? "start" :"end"}

                  >
                    {i18n.t("product_box.order_now")}
                  </Typography>
                  {i18n.language === "arEG" && (
                  <img
                    src={arrow}
                    alt="arrow"
                    width={40}
                    style={{position: "relative", top: "10px", left: "10px"}}
                  />
                    
                  )}
                </ListItem>
              </List>

            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} p={4} sx={{direction: "rtl"}}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            gap={4}
            alignItems={"center"}
            height={"100%"}
          >
            <Box width={"240px"} position={"relative"} top={"-15%"}>
              <img
                src={data.images.img_2}
                alt="img1"
                width={"100%"}
                className={styles.img_2}
              />
            </Box>
            <Box width={"290px"} className={styles.img_1} mx={2} zIndex={2}>
              <img src={data.images.img_1} alt="img1" width={"100%"} />
            </Box>
          </Box>
          <Box
            width={"100%"}
            position={"relative"}
            bottom={"40%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box width={"230px"}>
              <img
                src={data.images.img_3}
                alt="img1"
                width={"100%"}
                className={styles.img_3}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
