import {
  LocalPhoneOutlined,
  LocationOnOutlined,
  Public
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardMedia,
  Divider,
  List,
  ListItem,
  Typography
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {avatar} from "../img";
import AddNewClient from "./admin/AddNewClient";

export default function PartnersCards({data}) {
  const {i18n} = useTranslation();
  return (
    <Box>
      <Card
        sx={{
          borderRadius:
            i18n.language === "arEG"
              ? "100px 20px 0px 0px"
              : "20px 100px 0px 0px"
        }}
      >
        <CardMedia
          component={"img"}
          alt={data?.name}
          src={data.large_logo || avatar}
          width={"100%"}
          height={300}
        />
        <List>
          <ListItem>
            <Typography component={"h2"} variant="h6">
              {data?.name}
            </Typography>
          </ListItem>
          <ListItem sx={{display: "flex", alignItems: "center", gap: 1}}>
            <Public sx={{color: "#666666"}} />
            <Typography
              color="#09c"
              component={"a"}
              href={data?.website}
              target="_blank"
              variant="subtitle1"
            >
              {data?.website}
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box width={"100%"} display={"flex"} alignItems={"center"}>
              <LocationOnOutlined />
              <Typography
                component={"a"}
                href={data?.location_link}
                target="_blank"
                color="#09c"
              >
                {i18n.t("AddNewClient.google_link")}
              </Typography>
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent="end"
              alignItems={"center"}
            >
              <LocalPhoneOutlined />
              <Typography px={0.5} variant="subtitle1" color="#666666">
                {data?.mobile}
              </Typography>
            </Box>
          </ListItem>
          <Divider />
          <ListItem>
            <AddNewClient full card url={'/client/request/create'} />
          </ListItem>
        </List>
      </Card>
    </Box>
  );
}
