import {Delete} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{position: "relative", display: "inline-flex"}}>
      <CircularProgress variant="indeterminate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function ProgressImages({data, deleteImage}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress !== 100) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 10
        );
      }, 200);
      return () => {
        clearInterval(timer);
      };
    }
  }, [progress]);

  return (
    <ListItem sx={{display :'flex' , justifyContent:"end"}}>
      {progress === 100 && (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={2}
        >
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={""}
            alignItems={"center"}
            gap={2}
          >
            <img src={URL.createObjectURL(data)} alt={data.name} width={50} />
            <Typography>{data.name}</Typography>
          </Box>
          <IconButton
            onClick={() => {
              deleteImage(data.name);
            }}
          >
            <Delete sx={{color: "#ef6a6a"}} />
          </IconButton>
        </Box>
      )}
      {progress !== 100 && <CircularProgressWithLabel value={progress} />}
    </ListItem>
  );
}
