import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {prefixer} from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

export default function RTLBox({children}) {
  const {i18n} = useTranslation();

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin]
  });
  const ltrCache = createCache({
    key: "mui"
  });

  return (
    <CacheProvider value={i18n.language === "arEG" ? cacheRtl : ltrCache}>
      {children}
    </CacheProvider>
  );
}
