import {Close, Menu} from "@mui/icons-material";
import {Box, Divider, Drawer, IconButton, List, ListItemButton} from "@mui/material";
import React, {useState} from "react";
import { logo_1 } from "../../img";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function MobileDrower({ data }) {
  const { i18n } = useTranslation();
  const navigate  = useNavigate()

  const [open, setOpen] = useState(false);
  const closeHandler = () => {
    setOpen(false);
        document.getElementById('body').style.overflow ="visible";

  };
  const openHandler = () => {
    setOpen(true);
    document.getElementById('body').style.overflow = 'hidden';
  };
  
  return (
    <Box>
      <IconButton onClick={openHandler} sx={{display :open?"none" :"block"}}>
        <Menu />
      </IconButton>
      <Drawer anchor='right' open={open} variant="persistent" onClose={closeHandler}  >
        <Box  width={300}  >
          <Box py={2} px={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <img src={logo_1} alt="logo_1" width={70} />
            <IconButton onClick={closeHandler}>
              <Close/>
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
        <List>
        {data.map((ele, index) => (
          <ListItemButton key={index} onClick={()=>navigate(`${ele.path}` ,closeHandler())}>
            {i18n.t(ele.title)}

          </ListItemButton>
        ))}
            <ListItemButton>
            {i18n.t("HeaderMenu.servise_Buttom")}
            </ListItemButton>
            <ListItemButton>
              {i18n.t("HeaderMenu.login_Buttom")}
            </ListItemButton>

        </List>

        </Box>
      </Drawer>
    </Box>
  );
}
