import {Box, Divider, Grid, IconButton, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import SubscribBox from "../SubscribBox";
import styles from "../../css/home.module.css";
import {
  Copyright,
  Email,
  Instagram,
  LocationOnOutlined,
  PhoneEnabledOutlined,
  Twitter
} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {Store} from "../../context/DataStore";
import { twitter } from "../../img";
import { snap } from "../../img";

export default function Footer() {
  const {mobileDiv , show ,setShow} = Store();
  const { i18n } = useTranslation();

  return (
    <Box bgcolor={"#68B3D4"} height={!show?250:450} position={"relative"}  >
      {show && (
      <Box
        position={'absolute'}
        left={"50%"}
        width={"80%"}
          className={styles.subscribe}
      >
        <SubscribBox />
      </Box>
        
      ) }
      <Grid container spacing={0}>
        <Grid
          item
          md={10}
          xs={10}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-end"}
          // height={!mobileDiv?330 :window.location.pathname !=='/'?200: 400}
        >
          <Box
            position={"relative"}
            color={"#fff"}
            display={"flex"}
            justifyContent={"end"}
            alignItems={"flex-end"}
            gap={2}
            flexDirection={mobileDiv && "column"}
            fontFamily={'Tajawal'}
            right={'8%'}
            pb={1}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
                          fontFamily={'Tajawal'}

            >
              <LocationOnOutlined />
              <Typography  fontFamily={'Tajawal'}>{i18n.t("location")}</Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Email />
              <Typography  fontFamily={'Tajawal'}>Info@digitallline.com</Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {/* <PhoneEnabledOutlined /> */}
              <Typography  fontFamily={'Tajawal'}> السجل التجاري 1010857853</Typography>
            </Box>

          </Box>
        </Grid>

        <Grid item md={2} xs={2} >
          <Box
            position={"relative"}
            color={"#fff"}
            display={"flex"}
            justifyContent={mobileDiv?"end":"center"}
            flexDirection={"column"}
            alignItems={"end"}
            pl={2}
            height={!show?200:400}
          >
            <IconButton target="_blank" href="https://www.snapchat.com/add/digitallinee?share_id=YMGXQIFdFiw&locale=ar-SA">
              <img  src={snap} alt="snap"  width={20}/>
            </IconButton>
            <IconButton target="_blank" href="https://twitter.com/DigitallLinee">
              <img src={twitter} alt="twitter"  width={20}/>
            </IconButton>
            <IconButton target="_blank" href="https://www.instagram.com/digitalllinee/?hl=ar">
              <Instagram sx={{color: "#fff"}} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Divider color={"#fff"} />

      <Typography  fontFamily={'Tajawal'} textAlign={"center"} pt={2} color={"#fff"}>
        {i18n.t("copy_right")} <Copyright sx={{fontSize: "15px"}} />
      </Typography>
    </Box>
  );
}
