import {Close, Logout, Menu} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon
} from "@mui/material";
import React, {useState} from "react";
import {logo_1} from "../../img";
import {mastrListAdmin} from "../../data/Lists";
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function AdminDrawerMobile() {
    const navigate = useNavigate()
  const {i18n} = useTranslation();
  const [open, setOpen] = useState(false);
  function openHandler() {
    setOpen(true);
  }
  function closeHandler() {
    setOpen(false);
  }

  return (
    <Box>
      <IconButton onClick={openHandler}>
        <Menu />
      </IconButton>
      <Drawer open={open}>
        <Box
          width={300}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <img src={logo_1} alt="logo-1" width={70} />
          <IconButton onClick={closeHandler}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box
          py={2}
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <List>
            {mastrListAdmin.map((ele, index) => (
              <ListItemButton key={index} onClick={()=>{navigate(ele.path , closeHandler())}}>
                <ListItemIcon>{ele.icon} </ListItemIcon>
                {i18n.t(ele.title)}
              </ListItemButton>
            ))}
          </List>
          <Box px={2}>
            <Button
              variant="contained"
              fullWidth
              sx={{direction: "ltr"}}
              endIcon={<Logout />}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
