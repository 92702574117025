import {Close} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Store} from "../../context/DataStore";
import RTLBox from "../RTLBox";

export default function AdminInfo({fun}) {
  const {i18n} = useTranslation();
  const [open, setOpen] = useState(false);
  const {userInfo} = Store();
  async function openHandler() {
    setOpen(true);
  }
  const closeHandler = () => {
      setOpen(false);
      fun()
  };
  return (
    <Box>
      <Button onClick={openHandler}>
        <Avatar />
        {i18n.t("g.user_info")}
      </Button>
      <Dialog open={open} fullWidth>
        <Box p={2}>
          <IconButton onClick={closeHandler}>
            <Close />
          </IconButton>
        </Box>
        <Box py={3}>
          <Typography
            textAlign={"center"}
            variant="h5"
            fontFamily={"Tajawal-b"}
          >
            {i18n.t("g.user_info")} {userInfo.name}
          </Typography>
        </Box>
        <DialogContent>
          <Container sx={{pb:4}}>
            <RTLBox>
              <form>
                <Grid container spacing={2} >
                  <Grid item md={6}>
                    <TextField
                      value={userInfo?.name}
                      size="small"
                      variant="filled"
                      fullWidth
                      label={i18n.t("AddNewClient.name")}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={userInfo?.email}
                      size="small"
                      variant="filled"
                      fullWidth
                      label={i18n.t("AddNewClient.email")}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={userInfo?.mobile}
                      size="small"
                      variant="filled"
                      fullWidth
                      label={i18n.t("AddNewClient.phone")}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      value={userInfo?.created_at}
                      size="small"
                      variant="filled"
                      fullWidth
                      label={i18n.t("AddNewClient.login_date")}
                    />
                  </Grid>
                </Grid>
              </form>
            </RTLBox>
          </Container>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
