import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import axios from "axios";
import {DataStoreProvider} from "./context/DataStore";
import {SnackbarProvider} from "notistack";

axios.defaults.baseURL ="https://api.digitalline.sa/api/v1"
 export const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <DataStoreProvider>
      <SnackbarProvider anchorOrigin={{horizontal: "center", vertical: "top"}}>
        <App />
      </SnackbarProvider>
    </DataStoreProvider>
  // </React.StrictMode>
);
