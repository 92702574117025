import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Store} from "../../context/DataStore";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {Close} from "@mui/icons-material";

export default function StuteDailog({
  stutes,
  title,
  user_id,
  statistics,
  role
}) {
  const {options} = Store();
  const {i18n} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [open, setOpen] = useState(false);
  const [newState, setNewState] = useState("");
  const [state, setState] = useState([]);
  const [val, setVal] = useState("");
  const getStutas = async () => {
    await axios
      .get(`/lookups/user-status`, {headers: options})
      .then((res) => {
        if (res.status === 200) {
          setState(res.data?.data);
          getVal(res.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getStutas();
  }, []);
  const getVal = (list) => {
    if (list) {
      list.map((ele) => {
        if (ele.title === stutes) {
          setVal(ele.id);
        }
      });
    }
  };
  const changeState = async (id) => {
    await axios
      .put(
        `/common/change-status`,
        {status_id: id, user_id: user_id, role: role},
        {headers: options}
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data?.data);
          enqueueSnackbar(`${res.data?.message}`, {variant: "success"});
          setNewState(res.data?.data?.status);
          if (window.location.pathname !== "/dashboard/suopervies") {
            statistics();
          }
          setVal(res.data?.data?.status_id);
          setOpen(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(`${err?.response?.data?.message}`, {variant: "error"});
      });
  };
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="text"
      >
        {newState ? newState : stutes}
      </Button>
      <Dialog open={open} maxWidth={"lg"}>
        <Box p={1}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box width={800}>
          <Typography
            py={1}
            variant="h6"
            fontFamily={"Tajawal-b"}
            textAlign={"center"}
          >
            {i18n.t(title)}
          </Typography>
          <DialogContent>
            <FormControl sx={{width: "100%"}}>
              <RadioGroup
                onChange={(e) => {
                  changeState(e.target.value);
                }}
                defaultValue={val ? val : null}
              >
                <Grid container spacing={1}>
                  {state?.map((ele, index) => (
                    <Grid item md={3} key={index}>
                      <FormControlLabel
                        value={ele.id}
                        control={<Radio />}
                        label={ele.title}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}
