import {
  Close,
  MapOutlined,
  NoteAltOutlined,
  SearchOutlined
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import AddNewUnit from "./AddNewUnit";
import ImagesSwiper from "../productPage/ImagesSwiper";
import axios from "axios";
import {Store} from "../../context/DataStore";
import SelectType from "../SelectType";

export default function RealEstateUnits() {
  const [items, setItems] = useState([]);

  const {i18n} = useTranslation();
  const {options} = Store();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(null);
  const getData = async () => {
    await axios
      .get("/real-estate-devs/dev-items", {headers: options})
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const changeHandel = async (e, p) => {
    await axios
      .get(`/real-estate-devs/items?page=${p}`, {headers: options})
      .then((res) => {
        setData(res.data.data);
      });
  };

  const getItems = async () => {
    await axios
      .get("/lookups/item-types", {headers: options})
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const filterData = async (id) => {
    if (id === 0) {
      getData();
    } else {
      await axios
        .get(`/real-estate-devs/items/filter/type/${id}`, {headers: options})
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const searchItem = async (id) => {
    await axios
      .get(`/real-estate-devs/items/search/${id}`, {headers: options})
      .then((res) => {
        console.log(res)
        setSearch(res.data);
      })
      .catch((err) => {
        getData()
        setSearch(null)
      });
  };

  useEffect(() => {
    getItems();
  }, []);
  useEffect(() => {
    if (search === "" || null || undefined) {
      getData();
    }
  }, []);
  return (
    <Box>
      <Typography
        py={5}
        component={"h4"}
        fontFamily={"Tajawal-b"}
        color={"#000"}
        variant="h5"
        fontWeight={900}
      >
        {i18n.t("RealEstateUnits.title")}
      </Typography>
      <ButtonGroup>
        <Button
          variant="text"
          onClick={() => {
            filterData(0);
          }}
        >
        {i18n.t("g.all")}
        </Button>
        {items?.map((ele, index) => (
          <Button
            variant="text"
            onClick={() => {
              filterData(ele.id);
            }}
            key={index}
          >
            {ele.title}{" "}
          </Button>
        ))}
      </ButtonGroup>
      <Divider />
      <Box>
        <Box
          width={"100%"}
          bgcolor={"#D9E0E2"}
          display={"flex"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
          py={1}
          my={3}
        >
          <Box
            borderRadius={2}
            width={"50%"}
            p={0.5}
            bgcolor={"#fff"}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <SearchOutlined sx={{color: "GrayText"}} />
            <InputBase
              fullWidth
              placeholder={i18n.t("g.search_units")}
              onChange={(e) => {
                searchItem(e.target.value);
              }}
            />
          </Box>
          <AddNewUnit fun={getData} />
        </Box>
      </Box>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.name")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.developer_name")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.address")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.city")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.area")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.area2")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.pic")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.price")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.dics_table")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.edit")}
                  </Typography>
                </TableCell>
                <TableCell align={"center"}>
                  <Typography color={"#000"}>
                    {i18n.t("RealEstateUnits.form.stute")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(search || data)?.data?.map((ele, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    <Typography>{ele.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{ele.developer_name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      target="_blank"
                      href={ele.location}
                    >
                      <MapOutlined />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{ele.city.title}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{ele.area}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {ele.space} م<sup>2</sup>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <ImagesSwiper data={ele.medium_file} />
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{ele.price}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <EditNotes notes={ele.description} />
                  </TableCell>
                  <TableCell align="center">
                    <AddNewUnit data={ele} fun={getData} />
                  </TableCell>
                  <TableCell align="center">
                    <SelectType type={ele.status.id} id={ ele.id}/>

                    {/* <Typography
                      bgcolor={ele.status.id === 1 ? "#FDF4F6" : "#EBF9F4"}
                      color={ele.status.id === 1 ? "#26AE7C": "#DF5173" }
                      p={0.5}
                      borderRadius={5}
                    >
                      {ele.status.title}
                    </Typography> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            py={2}
          >
            <Stack spacing={2} sx={{direction: "ltr"}}>
              <Pagination
                count={data?.meta?.last_page}
                variant="outlined"
                shape="rounded"
                page={data?.meta?.current_page}
                onChange={changeHandel}
              />
            </Stack>
          </Box>
        </TableContainer>
      </Box>
    </Box>
  );
}
export const EditNotes = ({notes}) => {
  const {i18n} = useTranslation();

  // const [note, setNote] = useState(notes ? notes : "");
  const [open, setOpen] = useState(false);
  const openHandler = () => {
    setOpen(true);
  };

  return (
    <>
      {notes ? (
        <>
          {" "}
          <Typography color={"#000"} width={50} noWrap>
            {notes}
          </Typography>
          {notes !== "" && (
            <Typography
              color={"#09c"}
              sx={{cursor: "pointer"}}
              component={"a"}
              onClick={() => {
                setOpen(true);
              }}
            >
              more{" "}
            </Typography>
          )}
        </>
      ) : (
        <IconButton onClick={openHandler}>
          <NoteAltOutlined />
        </IconButton>
      )}
      <Dialog open={open} fullWidth>
        <Box p={2}>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <Typography
          textAlign={"center"}
          fontFamily={"Tajawal-b"}
          fontWeight={700}
          variant="h6"
        >
          {i18n.t("All_tables.uint_discrption")}
        </Typography>
        <DialogContent>
          <Typography component={"p"} textAlign={""} p={2}>
            {notes}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
