import React, {useEffect} from "react";
import {Store} from "../context/DataStore";
import {
  Box,
  Container,
  Grid,
  Typography
} from "@mui/material";
import { about_2, cardImage_3} from "../img";
import {useTranslation} from "react-i18next";
import PartnerSwiper from "../components/PartnerSwiper";
import MastarTitle from "../components/MastarTitle";

export default function About() {
  const {i18n} = useTranslation();
  const {setShow} = Store();
  useEffect(() => {
    setShow(false);
  }, []);

  return (
    <Box mt={10}>
      <Container>
        <Grid container spacing={1} >
          <Grid item md={6} xs={12} bgcolor={"#F3FAFC"} borderRadius={5}>
            <Box>
              <Typography
                fontFamily={"Tajawal-b"}
                textAlign={"center"}
                component={"h2"}
                variant="h4"
                pt={2}
              >
                {i18n.t("about.title")}
              </Typography>
            </Box>
            <Box p={2} py={3} color={'#ACABAB'} px={2}>
              <Typography component={"p"} lineHeight={2} >
                {i18n.t('about.disc')}
              </Typography>
              <Typography component={"p"} lineHeight={2} >
                {i18n.t('about.disc_2')}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
            py={3}
            display={"flex"}
            justifyContent={"end"}
          >
            <Box
              p={2}
              border={"2px solid #5494AC"}
              borderRadius={"77px 15px 77px 16px"}
              width={"70%"}
            >
              <img  src={cardImage_3} alt={"about"} width={"95%"} height={"93%"} />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          mt={8}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item md={5} xs={12} py={3} height={"100%"} >
            <Box
              p={2}
              border={"2px solid #5494AC"}
              borderRadius={"97px 15px 97px 16px"}
              width={"70%"}
            >
              <img src={about_2} alt={"about"} width={"100%"} />
            </Box>
          </Grid>
          <Grid item md={6} xs={12}  bgcolor={"#F3FAFC"} borderRadius={5}>
            <Box py={3} >
              <Typography
                fontFamily={"Tajawal-b"}
                textAlign={"center"}
                component={"h2"}
                variant="h4"
              >
                {i18n.t("g.message_verion")}
              </Typography>
            </Box>
            <Box py={2} color={'#ACABAB'} px={2}>
              <Typography component={"p"} lineHeight={2}>
              {i18n.t('about.company')}
              </Typography>
              <Typography component={"h3"} lineHeight={2} variant="h5" fontFamily={"Tajawal-b"}>
              {i18n.t('about.vision')}:-
              </Typography>
              <Typography component={"p"} lineHeight={2} variant="body1" >
              {i18n.t('about.vision_disc')}
              </Typography>
              <Typography component={"h3"} lineHeight={2} variant="h5" fontFamily={"Tajawal-b"}>
              {i18n.t('about.gools')}:-
              </Typography>
              <Typography component={"p"} lineHeight={2} variant="body1" >
              {i18n.t('about.gools_disc')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{mt: 10, pb: 10}}>
        <MastarTitle title={i18n.t("product_box.cards.card_2.title")} />
        <PartnerSwiper />
      </Container>
    </Box>
  );
}
