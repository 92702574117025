import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Card, TextField, Typography, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";

export default function Steper() {
  const { i18n } = useTranslation();
  const steps = [
    i18n.t("stepper.titles.redeem_voucher"),
    i18n.t("stepper.titles.mobile_validation"),
    i18n.t("stepper.titles.thanks")
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [loading, setLoading] = React.useState(false);


  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

    const voucherValidation = yup.object({
      voucherCode: yup.string().required(i18n.t("stepper.formes.voucher_code_r")),
      nationalId: yup.string().required(i18n.t("stepper.formes.national_id_r")),
      applicationId: yup.string().required(i18n.t("stepper.formes.application_id_r"))
    });
    const mobileValidation = yup.object({
      code: yup.string().required(i18n.t("stepper.formes.mobile_validationـr"))
    });

    const voucherForm = useFormik({
        validationSchema: voucherValidation,
        initialValues: {
          voucherCode: "",
          nationalId: "",
          applicationId: ""
        },
        onSubmit: (values) => {
          console.log(values);
        }
    })
    const codeForm = useFormik({
      validationSchema: mobileValidation,
      initialValues: {
        code: "",
      },
      onSubmit: (values) => {
        console.log(values);
      }
    });


  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <Typography mx={1}>{label}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 3 ? (
        <React.Fragment>
          <Box py={5}>
            <Card
              sx={{
                p: 5,
                maxWidth: "60%",
                marginX: "auto",
                border: "1px solid #5494AC",
                borderRadius: "10px",
                height: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 5
              }}
            >
              <Typography
                align="center"
                fontWeight={700}
                variant="h5"
                component={"h1"}
              >
                {i18n.t("stepper.titles.thanks")}
              </Typography>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography>
                  {i18n.t("stepper.titles.voucher_amount")}
                  {" : "}
                  <Typography
                    variant="subtitle1"
                    color={"green"}
                    component={"span"}
                  >
                    2800 {i18n.t("dashboard.Home.g.Currency")}{" "}
                  </Typography>
                </Typography>
                <Typography>
                  {i18n.t("stepper.titles.product_amount")}
                  {" : "}
                  <Typography
                    variant="subtitle1"
                    color={"green"}
                    component={"span"}
                  >
                    2800 {i18n.t("dashboard.Home.g.Currency")}{" "}
                  </Typography>
                </Typography>
                <Typography>
                  {i18n.t("stepper.titles.amount_to_wallet")}
                  {" : "}
                  <Typography
                    variant="subtitle1"
                    color={"green"}
                    component={"span"}
                  >
                    0 {i18n.t("dashboard.Home.g.Currency")}{" "}
                  </Typography>
                </Typography>
                <Divider sx={{ borderBlockColor: "#5494AC", my: 2 }} />
                <Typography>
                  {i18n.t("stepper.titles.voucher")}
                  {" : "}
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    fontWeight={700}
                  >
                    xxxxxxxx
                  </Typography>
                </Typography>
                <Typography
                  component={"span"}
                  align="center"
                  bgcolor={"yellow"}
                >
                  {i18n.t("stepper.titles.redeemed")}
                </Typography>
              </Box>
            </Card>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 && (
            <Box my={5}>
              <form onSubmit={voucherForm.handleSubmit}>
                <Card
                  sx={{
                    p: 5,
                    maxWidth: "60%",
                    marginX: "auto",
                    border: "1px solid #5494AC",
                    borderRadius: "10px"
                  }}
                >
                  <Typography
                    align="center"
                    fontWeight={700}
                    variant="h5"
                    component={"h1"}
                  >
                    {i18n.t("stepper.titles.redeem_voucher")}
                  </Typography>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                      <label>{i18n.t("stepper.forms.voucher_code")} </label>
                      <TextField variant="outlined" size="small" fullWidth />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                      <label> {i18n.t("stepper.forms.national_id")}</label>
                      <TextField variant="outlined" size="small" fullWidth />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                      <label> {i18n.t("stepper.forms.application_id")}</label>
                      <TextField variant="outlined" size="small" fullWidth />
                    </Box>
                    <LoadingButton
                      variant="contained"
                      loading={loading}
                      sx={{ color: "#000", fontWeight: 600 }}
                    >
                      Check out
                    </LoadingButton>
                  </Box>
                </Card>
              </form>
            </Box>
          )}
          {activeStep === 1 && (
            <Box my={5}>
              <form onSubmit={codeForm.handleSubmit}>
                <Card
                  sx={{
                    p: 5,
                    maxWidth: "60%",
                    marginX: "auto",
                    border: "1px solid #5494AC",
                    borderRadius: "10px",
                    height: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    align="center"
                    fontWeight={700}
                    variant="h5"
                    component={"h1"}
                  >
                    {i18n.t("stepper.titles.mobile_validation")}
                  </Typography>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                      <label>
                        {i18n.t("stepper.forms.mobile_validation")}{" "}
                      </label>
                      <TextField variant="outlined" size="small" fullWidth />
                      <Typography>
                        {i18n.t("stepper.titles.voucher_amount")}
                        {" : "}
                        <Typography
                          variant="subtitle1"
                          color={"green"}
                          component={"span"}
                        >
                          2800 {i18n.t("dashboard.Home.g.Currency")}{" "}
                        </Typography>
                      </Typography>
                    </Box>
                    <LoadingButton
                      variant="contained"
                      loading={loading}
                      sx={{ color: "#000", fontWeight: 600 }}
                    >
                      Confirm and Checkout
                    </LoadingButton>
                  </Box>
                </Card>
              </form>
            </Box>
          )}
        </React.Fragment>
      )}
    </Box>
  );
}
