import {
  AppBar,
  CssBaseline,
  ThemeProvider,
  Toolbar,
  createTheme
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import {Outlet} from "react-router-dom";
import Navbar from "../components/header/Navbar";
import Footer from "../components/footer/Footer";
import { arEG } from '@mui/material/locale';
import { Store } from "../context/DataStore";
import { useTranslation } from "react-i18next";

export default function ClientLayout() {
  const {i18n}=useTranslation()
  const {corectDir} =Store()
  const theme = createTheme({
    typography: {
      fontFamily: "Tajawal",
    
      
    },
    arEG,
    palette: {
      primary: {
        main: "#5494AC"
      },
      secondary: {
        main: "#000"
      }
    }
  });
  useMemo(() => {
    corectDir(i18n.language)
  },[])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <AppBar
        position="static"
        sx={{p: 2, bgcolor: "#fff", color: "#5494AC"}}
        elevation={0}
      >
        <Toolbar>
          <Navbar />
        </Toolbar>
      </AppBar>
      <main>
        <Outlet />
      </main>
      <footer>
        <Footer/>
      </footer>
      </ThemeProvider>
  );
}
