import "./App.css";
import {RouterProvider} from "react-router-dom";
import {Router} from "./router/Router";
import {useEffect} from "react";

function App() {
  useEffect(() => {}, []);
  return <RouterProvider router={Router}></RouterProvider>;
}

export default App;
