import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';
import RegisterForm from './RegisterForm';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function LoginTab({fun}) {
  const {i18n}=useTranslation()
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%'  }} > 
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} width={'100%'} display={'flex'} justifyContent={'center'} >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={i18n.t('login.login')} {...a11yProps(0)} />
          <Tab label={i18n.t('login.register')} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
     <LoginForm fun={handleChange}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <RegisterForm fun={fun} />
      </CustomTabPanel>
    </Box>
  );
}