import {AppBar, Box, Container, Toolbar} from "@mui/material";
import React from "react";
import {logo_1} from "../../img";
import SearchAdminBox from "./SearchAdminBox";
import NavigateNavAdmin from "./NavigateNavAdmin";
import { Store } from "../../context/DataStore";
import AdminDrawerMobile from "./AdminDrawerMobile";
import { Link } from "react-router-dom";

export default function AdminNav() {
  const {mobileDiv}=Store()
  return (
    <>
      <AppBar position="static" elevation={0}>
        <Toolbar sx={{bgcolor: "#fff", py: 2}}>
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box>
              <Link to={'/'}>
              <img src={logo_1} alt="logo_1" width={150} />

              </Link>
            </Box>
            {!mobileDiv ? (
              <>
            <Box>
              <SearchAdminBox />
            </Box>
            <Box>
              <NavigateNavAdmin/>
            </Box>

              </>
            ):(<AdminDrawerMobile/>)}
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
