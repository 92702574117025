import React from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "../css/swiper.css";

// import required modules
import {FreeMode, Pagination, Autoplay ,Navigation} from "swiper/modules";
import {Box} from "@mui/material";
import {  p_3 } from '../img';
import {useGetData} from "../hooks/getData";

export default function PartnerSwiper() {
  const { data  } = useGetData("/partners/all");
  return (
    <Box height={"50vh"}>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true
        }}
        navigation
        modules={[FreeMode, Pagination, Autoplay,Navigation]}
        className="mySwiper"
      >
        {data?.data?.map((ele, index) => (
          <SwiperSlide key={index}>
            <img src={ele?.large_logo ||p_3} alt="الشركاء" width={"50%"} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
