import {Box, Button, Dialog, DialogContent, Grid, IconButton, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import RTLBox from "../RTLBox";
import * as yup from "yup";
import {useFormik} from "formik";
import {Close, Edit} from "@mui/icons-material";
import axios from "axios";
import {enqueueSnackbar} from "notistack";
import {Store} from "../../context/DataStore";

export default function AddNewSeller({items, fun, statistics}) {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const {options} = Store();
  const {i18n} = useTranslation();
  const regex = /(05|01)[0-9]{8}/;
  const regexId = /[0-9]{10}/;
  
  async function openHandler() {
    await getItemsDetails().then(() => {
      setOpen(true)
    })
  }
  
  const getItemsDetails = async () => {
    await axios
     .get(`/common/search-user/${items?.mobile}`, {headers: options})
     .then((res) => {
       if (res.status === 200) {
         setData(res.data.data[0]);
       }
     })
     .catch((err) => {
       console.log(err);
     });
  };
  const validationSchema = yup.object({
    name: yup.string().min(2).required(i18n.t("AddNewClient.name_required")),
    phone: yup
     .string()
     .matches(regex, i18n.t("g.vaild_phone"))
     .max(10, i18n.t("g.vaild_phone_number"))
     .required(i18n.t("AddNewClient.phone_required")),
    job: yup.string().required(i18n.t("AddNewClient.job_required")),
    id: yup
     .string()
     .matches(regexId, i18n.t("g.vild_id"))
     .max(10)
     .required(i18n.t("AddNewClient.id_required")),
    email: yup.string().email().required(i18n.t("AddNewClient.email_required")),
    password: !data
     ? yup.string().required(i18n.t("AddNewClient.Password_required"))
     : yup.string(),
    financing: yup.string().required()
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: "",
      phone: "",
      job: "",
      id: "",
      email: "",
 password: data ? "*******" : "",
      userName: "",
      financing: ""
    },
    onSubmit: async (values) => {
      const newSales = {
        name: values.name,
        mobile: values.phone,
        email: values.email,
        password: values.password === "*******" ? "" : values.password,
        identity_number: values.id,
        job_title: values.job,
        investor: values.financing
      };
      if (data) {
        await axios
         .put(`/sales/update/${data.id}`, newSales, {
           headers: options
         })
         .then((res) => {
           if (res.data.success === true) {
             enqueueSnackbar(`${res.data.message}`, {variant: "success"});
             statistics();
             formik.handleReset();
             formik.initialValues = {};
             getItemsDetails();
             setOpen(false)
           }
         })
         .catch((err) => {
           enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
           console.log(err);
         }).finally(() => {
           fun()
         });
      } else {
        await axios
         .post("/sales/register", newSales, {
           headers: options
         })
         .then((res) => {
           if (res.data.success === true) {
             enqueueSnackbar(`${res.data.message}`, {variant: "success"});
             fun();
             statistics();
             formik.values = {};
             formik.handleReset();
             setOpen(false);
           }
         })
         .catch((err) => {
           enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
           console.log(err);
         });
      }
    }
  })
  useEffect(() => {
    formik.values.email = data?.email;
    formik.values.name = data?.name;
    formik.values.financing = data?.investor;
    formik.values.id = data?.identity_number;
    formik.values.phone = data?.mobile;
    formik.values.job = data?.job_title;
  }, [data]);
  return (
   <>
     {items ? (
      <IconButton onClick={openHandler}>
        {" "}
        <Edit color="primary"/>{" "}
      </IconButton>
     ) : (
      <Button
       onClick={() => {
         setOpen(true);
       }}
       variant="contained"
       sx={{
         bgcolor: "#CCDFF2",
         color: "#44A5FF",
         ":hover": {bgcolor: "#44A5FF", color: "#CCDFF2"}
       }}
      >
        {i18n.t("AddNewClient.title")}
      </Button>
     )}
     <Dialog open={open} fullWidth>
       <Box p={2}>
         <IconButton
          onClick={() => {
            formik.handleReset(setOpen(false));
          }}
         >
           <Close/>
         </IconButton>
       </Box>
       <Typography textAlign={"center"} variant="h5" fontFamily={"Tajawal-b"}>
         {data
          ? i18n.t("AddNewClient.edit_Title")
          : i18n.t("AddNewClient.title")}
       </Typography>
       
       <DialogContent>
         <RTLBox>
           <form onSubmit={formik.handleSubmit}>
             <Grid container spacing={1}>
               <Grid item md={6} my={1}>
                 <TextField
                  size="small"
                  fullWidth
                  value={formik.values.name || data?.name}
                  variant="outlined"
                  label={i18n.t("AddNewClient.name")}
                  name="name"
                  inputProps={{type: "text"}}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  placeholder={i18n.t("AddNewClient.name_hint")}
                 />
               </Grid>
               <Grid item md={6} my={1}>
                 <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={formik.values.id || data?.identity_number}
                  name="id"
                  label={i18n.t("AddNewClient.id")}
                  inputProps={{type: "text", maxLength: 10}}
                  onChange={formik.handleChange}
                  error={formik.touched.id && Boolean(formik.errors.id)}
                  helperText={formik.touched.id && formik.errors.id}
                  placeholder={i18n.t("AddNewClient.id_hint")}
                 />
               </Grid>
               <Grid item md={6} my={1}>
                 <TextField
                  size="small"
                  fullWidth
                  value={formik.values.phone || data?.mobile}
                  label={i18n.t("AddNewClient.phone")}
                  variant="outlined"
                  name="phone"
                  inputProps={{type: "text", maxLength: 10}}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  placeholder={"05"}
                 />
               </Grid>
               <Grid item md={6} my={1}>
                 <TextField
                  size="small"
                  fullWidth
                  value={formik.values.email || data?.email}
                  variant="outlined"
                  label={i18n.t("AddNewClient.email")}
                  name="email"
                  inputProps={{type: "email"}}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                 />
               </Grid>
               <Grid item md={6} my={1}>
                 <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={formik.values.job || data?.job_title}
                  name="job"
                  label={i18n.t("AddNewClient.job")}
                  inputProps={{type: "text"}}
                  onChange={formik.handleChange}
                  error={formik.touched.job && Boolean(formik.errors.job)}
                  helperText={formik.touched.job && formik.errors.job}
                 />
               </Grid>
               <Grid item md={6} my={1}>
                 <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={formik.values.financing || data?.investor}
                  name="financing"
                  label={i18n.t("AddNewClient.financing")}
                  inputProps={{type: "text"}}
                  onChange={formik.handleChange}
                  error={
                   formik.touched.financing &&
                   Boolean(formik.errors.financing)
                  }
                  helperText={
                   formik.touched.financing && formik.errors.financing
                  }
                 />
               </Grid>
               <Grid item md={6} my={1}>
                 <TextField
                  size="small"
                  fullWidth
                  value={formik.values.phone || data?.mobile}
                  variant="outlined"
                  name="userName"
                  label={i18n.t("AddNewClient.userName")}
                  inputProps={{type: "text"}}
                  // disabled
                  aria-readonly
                  focused={formik.values.phone !== "" ? true : false}
                  // onChange={formik.handleChange}
                  error={
                   formik.touched.userName && Boolean(formik.errors.userName)
                  }
                  helperText={
                   formik.touched.userName && formik.errors.userName
                  }
                 />
               </Grid>
               <Grid item md={6} my={1}>
                 <TextField
                  size="small"
                  fullWidth
                  defaultValue={formik.values.password}
                  variant="outlined"
                  inputProps={{type: "password"}}
                  value={formik.values.password}
                  name="password"
                  label={i18n.t("AddNewClient.password")}
                  onChange={formik.handleChange}
                  error={
                   formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                   formik.touched.password && formik.errors.password
                  }
                 />
               </Grid>
             </Grid>
             <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"end"}
              py={3}
              px={2}
             >
               <Button
                sx={{
                  borderRadius: "15px 50px",
                  bgcolor: "#2391BC",
                  fontFamily: "Tajawal-b",
                  width: "120px"
                }}
                variant="contained"
                type="submit"
               >
                 {i18n.t("AddNewClient.add")}
               </Button>
             </Box>
           </form>
         </RTLBox>
       </DialogContent>
     </Dialog>
   </>
  );
}
