import {Box, Grid, IconButton, Typography} from "@mui/material";
import React from "react";
import {excel, pdf, reports} from "../img";
import { useTranslation } from "react-i18next";

const Component = ({data}) => {
  return (
    <Box
      border={".5px solid #E0E0E0"}
      borderRadius={"10px"}
      width={"100%"}
          height={220}
          bgcolor={"#fafafa"}
          p={2}
    >
      <Box display={"flex"} justifyContent={"end"} width={"100%"} >
        <Box>
          <IconButton href={data.excelLink} target="_blank">
            <img src={excel} alt="excel" />
          </IconButton>
          <IconButton href={data.pdfLink} target="_blank">
            <img src={pdf} alt="pdf" />
          </IconButton>
        </Box>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        height={200}
        flexDirection={"column"}
      >
        <img src={reports} alt="excel" />
        <Typography my={3}>{data.title}</Typography>
      </Box>
    </Box>
  );
};

export default function Reports() {
  const {i18n} =useTranslation()
  const List = [
    {
      title: i18n.t('report_1'),
      pdfLink: "https://api.digitallline.com/reports/sales/pdf",
      excelLink: "https://api.digitallline.com/reports/sales/excel",
    },
    {
      title: i18n.t('report_2'),
      pdfLink: "",
      excelLink: "",
    },
    {
      title: i18n.t('report_3'),
      pdfLink: "https://api.digitallline.com/reports/sales/pdf",
      excelLink: "https://api.digitallline.com/reports/sales/excel",
    },
    {
      title: i18n.t('report_4'),
      pdfLink: "",
      excelLink: "",
    },
    {
      title: i18n.t('report_5'),
      pdfLink: "",
      excelLink: "",
    },
  ]
  return (
    <Box bgcolor={"#fff"} m={5} p={5} borderRadius={3}> 
      <Grid container spacing={2}>
        {List.map(( ele, index) => (
          <Grid item key={index} xs={12} md={6} lg={4}>
            <Component data={ele} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
