import {Box, Container, Grid, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Store} from "../context/DataStore";
import {cardImage_3} from "../img";

export default function PrivacyPolicy() {
  const {i18n} = useTranslation();
  const {setShow} = Store();
  useEffect(() => {
    setShow(false);
  });
  return (
    <Box>
      <Typography
        fontFamily={"Tajawal-b"}
        fontWeight={800}
        component={"h1"}
        variant="h5"
        textAlign={"center"}
        py={5}
      >
        {i18n.t("HeaderMenu.privacy_policy")}
      </Typography>

      <Box py={10} >
        <Container>
          <Grid container spacing={1}>
            <Grid item md={8}  >
              <Box>
                <Typography
                  fontFamily={"Tajawal-b"}
                  fontWeight={800}
                  component={"h2"}
                  variant="h3"
                >
                  {i18n.t("policy")}
                </Typography>
              </Box>
              <Box color={"#000"}>
                <Typography py={2}>
                  _{i18n.t("policy_prag_1")}
                </Typography>
                <Typography fontWeight={800}>
                  _{i18n.t("policy_prag_2")}
                </Typography>
                <Typography py={2}>
                  _{i18n.t("policy_prag_3")}
                </Typography>
                <Typography py={0.5}>_ {i18n.t("policy_prag_5")}</Typography>
                <Typography py={0.5}>_ {i18n.t("policy_prag_6")}</Typography>
                <Typography py={0.5}>_ {i18n.t("policy_prag_7")}</Typography>
                <Typography py={0.5}>_ {i18n.t("policy_prag_8")}</Typography>

                <Typography
                  variant="h6"
                  py={2}
                  fontFamily={"Tajawal-b"}
                  fontWeight={800}
                >
                  {i18n.t("policy_prag_4_title")}
                </Typography>

                <Typography>_{i18n.t("policy_prag_4")}</Typography>
              </Box>
              <Box color={"#000"}>
                <Typography
                  variant="h6"
                  py={2}
                  fontFamily={"Tajawal-b"}
                  fontWeight={800}
                >
                  {i18n.t("policy_parg_9_title")}
                </Typography>
                <Typography py={0.5}>_ {i18n.t("policy_prag_9")}</Typography>
                <Typography py={0.5}>_ {i18n.t("policy_prag_10")}</Typography>

                <Typography
                  variant="h6"
                  py={2}
                  fontFamily={"Tajawal-b"}
                  fontWeight={800}
                >
                  {i18n.t("policy_parg_11_title")}
                </Typography>
                <Typography py={0.5}>_ {i18n.t("policy_prag_11")}</Typography>
                <Typography
                  variant="h6"
                  py={2}
                  fontFamily={"Tajawal-b"}
                  fontWeight={800}
                >
                  {i18n.t("policy_parg_12_title")}
                </Typography>

                <Typography py={0.5}>_ {i18n.t("policy_prag_12")}</Typography>

                <Typography
                  variant="h6"
                  py={2}
                  fontFamily={"Tajawal-b"}
                  fontWeight={800}
                >
                  {i18n.t("policy_parg_14_title")}
                </Typography>
                <Typography py={1}>_{i18n.t("policy_parg_14")} </Typography>
                <Typography py={0.5}>_{i18n.t("policy_parg_13")}</Typography>
              </Box>
            </Grid>
            <Grid item md={4} >
              <Box
                p={2}
                border={"2px solid #5494AC"}
                borderRadius={"75px 15px 87px 16px"}
                width={450}
              >
                <img src={cardImage_3} alt="Tajawal" width={"100%"} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
