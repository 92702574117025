import {East, KeyboardBackspaceOutlined} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  List,
  ListItem,
  Typography
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ProductCard({data}) {
  const { i18n } = useTranslation();
  const navigate=useNavigate()

  return (
      <Card sx={{textAlign: "center", pt: 2 , width:"100%" , height:"100%"  , pb:"10px"}}>
        <img
          src={data.img}
          alt={data.title}
          width={"90%"}
        style={{ margin: "auto" }}
        
        />
        <List sx={{height :"200px"}}>
          <ListItem>
            <Typography component={"span"} fontFamily={'Tajawal'} color={"#5494AC"} variant="body2">
              {i18n.t(data.sub_title)}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography fontFamily={'Tajawal-b'} component={"h6"} variant="h5">
              {i18n.t(data.title)}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography
              textAlign={"start"}
              component={"p"}
              variant="subtitle2"
            color={"#B3B3BA"}
            fontFamily={'Tajawal'}
            >
              {i18n.t(data.disc)}
            </Typography>
        </ListItem>
      </List>
      <Box px={3} >
        <Button
          onClick={()=>{navigate(`${data.link}`)}}
              variant="contained"
              fullWidth
              sx={{borderRadius: "15px 50px", bgcolor: "#2391BC" , fontFamily:"Tajawal-b"}}
            >
          {i18n.language === "enUS" ? "know more" : "تعرف اكثر"}
          {i18n.language === "enUS" ?   <East sx={{fontSize: "30px", marginX: 1}} /> :   <KeyboardBackspaceOutlined sx={{fontSize: "30px", marginX: 1}} />}
          
            
            
            </Button>

      </Box>
      </Card>
  );
}
