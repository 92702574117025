import axios from "axios";
import {useEffect, useState} from "react";
import {Store} from "../context/DataStore";
import { useTranslation } from "react-i18next";

export const useGetData = (url) => {
  const {options} = Store();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {i18n}=useTranslation()

  const getData = async () => {
    return await axios.get(url, {headers: options});
  };
  useEffect(() => {
    getData()
      .then((res) => {
        if (res.data) {
          setData(res.data.data);
          setLoading(false);
          setError(null);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, []);
  useEffect(() => {
    getData()
      .then((res) => {
        if (res.data) {
          setData(res.data.data);
          setLoading(false);
          setError(null);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [i18n.language]);
    
    return{data , error , loading , setData}
};
