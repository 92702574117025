import React, {useEffect} from "react";
import AdminNav from "../components/admin/AdminNav";
import {Outlet, useNavigate} from "react-router-dom";
import Footer from "../components/footer/Footer";
import {Store} from "../context/DataStore";
import {CssBaseline, createTheme} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {arEG} from "@mui/material/locale";
import {useSnackbar} from "notistack";
import { mastrListAdmin } from "../data/Lists";

export default function AdminLayout() {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {setShow, userInfo , setAdminState} = Store();
  const theme = createTheme({
    typography: {
      fontFamily: "Tajawal"
    },
    arEG
  });

  useEffect(() => {
    setShow(false);
  });
  const role = ["admin", "sales", "developer"];
  const permotionList = () => {
    if (userInfo?.permissions?.includes("8")) {
      // setS(mastrListAdmin);
      if (window.location.href.includes("?redirect=mail")) {
        navigate("/dashboard/orders");
      }
    } else {
      if (userInfo.permissions) {
        const i = mastrListAdmin.filter((ele) =>
          userInfo?.permissions?.find((x) => x === ele.value)
        );
        if (i) {
          // setS(i);
          if (window.location.href.includes("?redirect=mail")) {
            navigate("/dashboard/orders");
          } else {
            console.log(i[0]?.path)
            navigate(`${i[0]?.path}`);
          }
          // navigate(`${i[0]?.path}`);

        }
      }
    }
  };

  useEffect(() => {
    permotionList();
  }, []);


  useEffect(() => { 
    const allow = role.find((ele) => userInfo?.role === ele);
    if (!userInfo || !allow) {
      closeSnackbar();
      enqueueSnackbar("not allowed ", {variant: "error"});
      navigate("/");
      return;
    } else {
      if (allow === "admin") {
        setAdminState(true);
        // navigate("/dashboard");
        permotionList();

        return;

      }
      if (allow === "sales") {
        navigate("/dashboard/sales_representative");
        return;
      }
      if (allow === "developer") {
        navigate("/dashboard/developers");
        return;
      }
    }
  }, [userInfo]);
  return (
    <ThemeProvider ThemeProvider theme={theme}>
      <CssBaseline />
      <nav>
        <AdminNav />
      </nav>
      <main>
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </ThemeProvider>
  );
}
