import {Box, Container, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import InfoBox from "../components/admin/InfoBox";
import UserTableInfo from "../components/admin/UserTableInfo";
import {Store} from "../context/DataStore";
import SalesTable from "../components/admin/SalesTable";
import axios from "axios";
import {
  cancel_orders,
  checked_1,
  customer_review,
  project_management
} from "../img";
import {useTranslation} from "react-i18next";

export default function SalesRepresentative() {
  const [statistics, setStatistics] = useState("");
  const {i18n} = useTranslation();

  const salesInfo = [
    {
      img: customer_review,
      title: "dashboard.salesinfo.number_of_seles",
      number: statistics?.total || 0,
      label: "dashboard.orders.info.sales_lable"
    },
    {
      img: checked_1,
      title: "dashboard.salesinfo.accept_orders",
      number: statistics?.accepted || 0,
      label: "dashboard.orders.info.sales_lable"
    },
    {
      img: cancel_orders,
      title: "dashboard.salesinfo.cancel_orders",
      number: statistics.refused || 0,
      label: "dashboard.orders.info.sales_lable"
    }
  ];
  const salesPAgeInfo = [
    {
      img: customer_review,
      title: "dashboard.orders.info.all_orders",
      number: statistics?.total || 0,

      label: "dashboard.orders.info.client"
    },

    {
      img: checked_1,
      title: "dashboard.orders.info.accept_orders",
      number: statistics?.accepted || 0,
      label: "dashboard.orders.info.user"
    },
    {
      img: cancel_orders,
      title: "dashboard.orders.info.cancel_orders",
      number: statistics.refused || 0,
      label: "dashboard.orders.info.project"
    },
    {
      img: project_management,
      title: "dashboard.orders.info.hold_orders",
      number: statistics.pending || 0,
      label: "dashboard.orders.info.project"
    },
    {
      img: project_management,
      title: "dashboard.orders.info.hold_transfer",
      number: statistics.transfer || 0,
      label: "dashboard.orders.info.project"
    }
  ];

  const {adminState, options} = Store();

  const getStatistics = async () => {
    if (adminState) {
      await axios
        .get("/common/user-statistics/sales", {headers: options})
        .then((res) => {
          setStatistics(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get("/requests/statistics", {headers: options})
        .then((res) => {
          setStatistics(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <Box mt={5} p={5}>
      {adminState ? (
        <>
          {/* admin page */}

          <Container>
            <Typography
              py={5}
              component={"h4"}
              fontFamily={"Tajawal-b"}
              color={"#000"}
              variant="h5"
              fontWeight={900}
            >
              {i18n.t("g.sales_sit")}
            </Typography>
            <Grid container spacing={3}>
              {salesInfo.map((ele, index) => (
                <Grid item md={4} xs={12} key={index}>
                  <InfoBox ele={ele} />
                </Grid>
              ))}
            </Grid>
            <Typography
              py={5}
              component={"h4"}
              fontFamily={"Tajawal-b"}
              color={"#000"}
              variant="h5"
              fontWeight={900}
            >
              {i18n.t("dashboard.Home.List.seller")}{" "}
            </Typography>
          </Container>
          <SalesTable statistics={getStatistics} />
        </>
      ) : (
        <>
          {/* sales page */}

          <Box>
            <Container>
              <Typography
                py={5}
                component={"h4"}
                fontFamily={"Tajawal-b"}
                color={"#000"}
                variant="h5"
                fontWeight={900}
              >
              {i18n.t("g.client_sit")}{" "}
              </Typography>
              <Grid container spacing={3}>
                {salesPAgeInfo.map((ele, index) => (
                  <Grid item md={4} xs={12} key={index}>
                    <InfoBox ele={ele} />
                  </Grid>
                ))}
              </Grid>
              <Typography
                py={5}
                component={"h4"}
                fontFamily={"Tajawal-b"}
                color={"#000"}
                variant="h5"
                fontWeight={900}
              >
              {i18n.t("g.clients")}{" "}
              </Typography>
            </Container>
            <UserTableInfo statistics={getStatistics} />
          </Box>
        </>
      )}
    </Box>
  );
}
