import { Box, Typography } from '@mui/material'
import React from 'react'
import {high_quality} from '../img/index'
import { useTranslation } from 'react-i18next'

export default function HighQuality({ title }) {
    const {i18n}=useTranslation()
    
  return (
      <Box px={1} py={.2} gap={1} display={'flex'} alignItems={'center'}  justifyContent={'start'}   bgcolor={'#fff'} borderRadius={'40px 50px 50px 0px'}>
          <img src={high_quality} alt='high_quality' />
          <Typography fontFamily={'Tajawal'} component={'p'} variant="caption" >
              {i18n.t(`${title}`) }
          </Typography>
      
    </Box>
  )
}
