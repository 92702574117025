import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { emkan } from "../img";
import { useTranslation } from "react-i18next";
import { Store } from "../context/DataStore";
import EmkanConfig from "../components/EmkanConfig";

export default function PaymentSol() {
  const { i18n } = useTranslation();
  const { setShow } = Store();
  useEffect(() => {
    setShow(false);
  }, []);

  return (
    <Box height={"100%"} position={"relative"} pb={5}>
      <Typography
        fontFamily={"Tajawal-b"}
        fontWeight={800}
        component={"h1"}
        variant="h4"
        textAlign={"center"}
        py={2}
      >
        {i18n.t("HeaderMenu.payment")}
      </Typography>

      <Box py={5}>
        <Container sx={{ height: "" }}>
          <Grid container spacing={1} justifyContent={"space-between"}>
            <Grid item md={7} bgcolor={"#F3FAFC"} borderRadius={5}>
              <Box p={5}>
                <Typography lineHeight={2}>
                  تقديم حلول سداد للمتعثرين بهدف إعادة جدولة السداد وتوفير
                  السيولة المالية والقسائم الشرائية.
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <EmkanConfig />
              </Box>
            </Grid>
            <Grid
              height={"50vh"}
              item
              md={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                p={2}
                border={"2px solid #5494AC"}
                borderRadius={"97px 15px 97px 16px"}
                width={"100%"}
                bgcolor={"#3a3672"}
                height={"100%"}
                display={"flex"}
                alignItems={"center"}
              >
                <img src={emkan} alt="Tajawal" width={"100%"} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
